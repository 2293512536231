html { height: 100% }
body { height: 100%; display:flex}
#root, #root > div { flex: 1 1 auto; display: flex; flex-direction: column;}
#root > div > div.container { flex: 1 1 auto; display: flex; flex-direction: column; }

table.table > tbody > tr > td.dt-actions {
  white-space: nowrap;
  width: 50px;
}

.dt-actions > a, .dt-actions > button {
  vertical-align: middle;
  width: 3rem;
  display: inline-block;
  text-align: center;
  border-width: 0px;
}
.dt-actions > .btn {
  padding: 0;
}

td.dt-child {
  background-color: white;
  border: 1px solid #7abaff;
}

button.page-link {
  border-radius: 0;
}

button.btn-link.expando {
  padding:0;
}

.card {
  padding: .4rem 1rem 1rem 1.2rem;
  margin-right: 1rem;
}

.card > h6 {
  margin-left: -.8rem;
}

/*  ------- database styles -------- */
.badge-photo {
  width: 4.5em;
  height: 6em;
  border: 1px solid #444;
  margin-right: 1em;
}

.wacbar {
  text-align: center;
  text-transform: uppercase;
  font-size: .9;
}
.wac_Novice
{
  background-color: #d01616;
  color: #eeeeee;
}
.wac_Support
{
  background-color: #FFA500;
  color: black;
}
.wac_Field
{
  background-color: #008800;
  color: #eeeeee;
}
.wac_None {
    background-color: #ddd;
    color: black;
}